import { useEffect, useRef } from 'react';
import { formatRange } from 'utils/date';
import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';

type Props = {
  value: NonEmptyDateRange<Date>;
  onChange: (date: NonEmptyDateRange<Date> | null) => void;
};

export const CustomDatePicker = ({ onChange, value: [from, to] }: Props) => {
  const pickerRef = useRef<easepick.Core>();

  const handleChange = ({ start, end }: Record<string, Date>) => {
    onChange([start ?? new Date(), end ?? new Date()]);
  };

  useEffect(() => {
    const picker = new easepick.create({
      element: document.getElementById('datepicker') as HTMLElement,
      css: [
        'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
        'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.0/dist/index.css',
        '/static/picker.css',
      ],
      zIndex: 10,
      lang: 'ru-RU',
      format: 'DD MMM YYYY',
      grid: 2,
      calendars: 2,
      RangePlugin: {
        tooltip: false,
      },
      plugins: [RangePlugin],
      setup(picker) {
        picker.on('select', ({ detail }) => {
          handleChange(detail);
          picker.hide();
        });
      },
    });

    pickerRef.current = picker;
  }, []);

  const title = formatRange([from, to]);

  return (
    <div className="ml-2 text-main cursor-pointer underline" id="datepicker">
      {title}
    </div>
  );
};
