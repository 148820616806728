import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Rate, UsePaymentsParams } from './payment';
import format from 'date-fns/format';
import { endOfDay, startOfDay } from 'date-fns';

export const useAddDeposit = () => {
  const { data, mutateAsync, isLoading } = useMutation<{ value: string }, unknown, { value: string }>((vars) =>
    axios.post('/api/v1/deposit/', vars).then((res) => res.data),
  );

  return {
    isAdded: !!data?.value,
    addDeposit: mutateAsync,
    isAdding: isLoading,
  };
};

export type Deposit = {
  id: number;
  value: number;
  created_at: string;
  input_currency: string;
  input_value: string;
  output_currency: string;
  output_value: string;
  method: string;
  rate: Rate | null;
};

export type DepostisResponse = {
  results: Deposit[];
  count: number;
};

export const useDeposits = ({ from, to, page }: UsePaymentsParams) => {
  const dateAfter = format(startOfDay(from), 'yyyy-MM-dd');
  const dateBefore = format(endOfDay(to), 'yyyy-MM-dd');
  const { data, isLoading } = useQuery<DepostisResponse>(['getDeposits', dateAfter, dateBefore, page], () =>
    axios
      .get(`/api/v1/deposits/?date_after=${dateAfter}&date_before=${dateBefore}&page=${page}`)
      .then((res) => res.data),
  );

  return {
    isLoading,
    deposits: data?.results,
    count: data?.count ?? 0,
  };
};
