import { format, isToday, isYesterday } from 'date-fns';
import { ru } from 'date-fns/locale';

const formatDate = (date: Date) => {
  let formattedDate = format(date, 'd MMMM', { locale: ru });

  if (isToday(date)) {
    formattedDate = `сегодня, ${formattedDate}`;
  }

  if (isYesterday(date)) {
    formattedDate = `вчера, ${formattedDate}`;
  }

  return formattedDate;
};

export const formatRange = ([from, to]: NonEmptyDateRange<Date>) => {
  const fromDate = formatDate(from);
  const toDate = formatDate(to);

  if (fromDate === toDate) {
    return fromDate;
  }

  return `${fromDate} - ${toDate}`;
};
