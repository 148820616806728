import axios from 'axios';
import { endOfDay, format, startOfDay } from 'date-fns';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import qs from 'query-string';
import { create } from 'zustand';
import { chunk, debounce } from 'lodash';
import { createJSONStorage, persist } from 'zustand/middleware';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';
import { parseAmount } from 'utils/amount';

type PaymentStore = {
  payments: PaymentRowData[];
  totals: PayoutTotals | null;
  paymentMethods: Record<string, PaymentMethod>;
  fetchPaymentMethods: () => Promise<void>;
  fetchMethod: (index: number, payment: PaymentRowData) => void;
  loading: boolean;
  errorIndexes: number[];
  progress: number | null;
  estimateCommission: () => Promise<void>;
  setPayments: (payments: PaymentRowData[]) => Promise<void>;
  updateAmounts: (text: string, start?: number) => void;
  updatePayment: (index: number, payment: PaymentRowData) => void;
  createPayments: () => Promise<void>;
  submitPayments: () => Promise<void>;
  reset: () => void;
  append: () => void;
  remove: (id: number) => void;
};

export const defaultPaymentRowData = {
  id: 0,
  cardNumber: '',
  paymentMethod: {} as PaymentMethod,
  amount: 0,
  comment: '',
  secondaryAccount: '',
  backendId: null,
  suggestions: [],
};

export const fillDraftFrom = (id: number) => {
  return Array.from({ length: 10 }).map((_, i) => ({
    ...defaultPaymentRowData,
    id: i + id,
  }));
};

export const usePaymentTotal = () => {
  return usePaymentStore((state) => {
    if (!state.totals) {
      Sentry.captureMessage('No totals in payment store');

      return {
        totalWithCommission: 0,
        total: 0,
      };
    }

    return {
      totalWithCommission: state.totals.payouts_with_commission_value_rub,
      total: state.totals.payouts_value_rub,
    };
  });
};

export const usePaymentStore = create<PaymentStore>()(
  persist(
    (set, get) => ({
      payments: fillDraftFrom(0),
      totals: null,
      paymentMethods: {},
      loading: false,
      errorIndexes: [],
      progress: null,
      append: () =>
        set((state) => ({
          payments: [
            ...state.payments,
            { ...defaultPaymentRowData, id: (state.payments[state.payments.length - 1]?.id ?? 0) + 1 },
          ],
        })),
      reset: () => set({ payments: fillDraftFrom(0), loading: false, progress: null, totals: null, errorIndexes: [] }),
      remove: (id: number) => set((state) => ({ payments: state.payments.filter((payment) => payment.id !== id) })),
      setPayments: async (payments: PaymentRowData[]) => {
        set({ loading: true, progress: 0 });

        const chunks = chunk(payments, 100);

        for (const index in chunks) {
          const chunk = chunks[index];
          const accounts = chunk.map((payment) => payment.cardNumber);
          const body = { accounts };

          try {
            const response = await axios.post('/api/v1/batch-payment-methods/', body);

            for (const payment of chunk) {
              const account = payment.cardNumber.replace(/[^\d]/g, '');

              payment.suggestions = response.data[account] || [];
              payment.paymentMethod = payment.suggestions[0];
            }

            set({ progress: (parseInt(index, 10) / chunks.length) * 100 });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }

        set({ payments, loading: false, progress: null });
      },
      updateAmounts: (text: string, start = 0) => {
        const amounts = text.split('\n').map((value) => parseAmount(value));

        set((state) => {
          const payments = [...state.payments];

          for (const index in amounts) {
            const payment = payments[parseInt(index, 10) + start];

            if (!payment) {
              continue;
            }

            payment.amount = amounts[index];
          }

          return {
            payments: payments,
          };
        });
      },
      fetchPaymentMethods: async () => {
        const response = await axios.get('/api/v1/payment-methods/');
        const methods: Record<string, PaymentMethod> = response.data.reduce(
          (acc: Record<string, PaymentMethod>, method: PaymentMethod) => {
            acc[method.slug] = method;

            return acc;
          },
          {},
        );

        set({ paymentMethods: methods });
      },
      fetchMethod: debounce(async (index, payment: PaymentRowData) => {
        const queryString = qs.stringify({ account: payment.cardNumber });
        const response = await axios.get(`/api/v1/payment-methods/?${queryString}`);

        set((state) => {
          const payments = [...state.payments];

          payments[index].suggestions = response.data;
          payments[index].paymentMethod = response.data[0] || {};
          payments[index].error = undefined;

          return {
            payments: payments,
          };
        });
      }, 1000),
      updatePayment: async (index, payment: PaymentRowData) => {
        if (get().payments[index].cardNumber !== payment.cardNumber) {
          get().fetchMethod(index, payment);
        }

        set((state) => {
          const payments = [...state.payments];

          payment.backendId = null;

          payments[index] = payment;

          return {
            payments: payments,
          };
        });
      },
      estimateCommission: async () => {
        const response = await axios.post<PayoutTotals>('/api/v1/estimate-bill/', {
          ids: get()
            .payments.filter((payment) => payment.backendId)
            .map((payment) => payment.backendId),
        });

        set({ totals: response.data });
      },
      createPayments: async () => {
        set({ loading: true, progress: 0, errorIndexes: [] });

        const payments = get().payments;
        const nonEmptyPayments = payments.filter((row: PaymentRowData) => row.amount > 0 || row.cardNumber);
        const ids = nonEmptyPayments.map((item: PaymentRowData) => item.id);

        // Creating payments on backend first
        // Then grabbing ids and send them to registry

        const chunks = chunk(nonEmptyPayments, 10);

        let failedIds: string[] = [];
        let failedIndexes: number[] = [];

        for (const index in chunks) {
          const chunk = chunks[index];
          const responses = await Promise.allSettled(
            chunk.map((item) => {
              if (item.backendId) {
                return Promise.resolve({ id: item.backendId });
              }

              if (!item.amount) {
                return Promise.reject({ response: { data: { amount: 'Укажите сумму' } } });
              }

              if (!item.paymentMethod) {
                return Promise.reject({ response: { data: { bank: 'Нужно указать способ оплаты' } } });
              }

              if (!item.cardNumber) {
                return Promise.reject({ response: { data: { account: 'Укажите номер карты или телефон' } } });
              }

              return axios
                .post<CreatePaymentResponse>('/api/v1/payout/', {
                  slug: item.paymentMethod.slug,
                  account: item.cardNumber,
                  secondary_account: item.secondaryAccount || undefined,
                  value: item.amount,
                  comment: item.comment,
                })
                .then((res) => res.data);
            }),
          );

          set({ progress: (parseInt(index, 10) / chunks.length) * 100 });

          const failedPayments = responses
            .map((result) => (result.status === 'rejected' ? result.reason?.response?.data : null))
            .reduce((prev, error, idx) => {
              return { ...prev, [ids[idx]]: error };
            }, {});

          failedIds = [...failedIds, ...Object.values(failedPayments).filter((id): id is string => id != null)];
          failedIndexes = [
            ...failedIndexes,
            ...Object.keys(failedPayments)
              .filter((id) => failedPayments[id])
              .map((i) => +i + 10 * +index),
          ];

          for (const idx in responses) {
            const response = responses[idx];
            const id = chunk[idx].id;
            const payment = payments.find((item) => item.id === id);

            if (!payment) {
              continue;
            }

            payment.error = response.status === 'rejected' ? response.reason?.response?.data : null;
            payment.backendId = response.status === 'fulfilled' ? response.value.id : null;
          }
        }

        set({ payments: payments, errorIndexes: failedIndexes, loading: false, progress: null });

        if (failedIds.length) {
          throw failedIds;
        }

        await get().estimateCommission();
      },
      submitPayments: async () => {
        const succeedIds = get()
          .payments.map((result) => result.backendId)
          .filter((id): id is number => id != null);

        try {
          await axios.post('/api/v1/registry/', { ids: succeedIds });
        } catch (e: unknown) {
          if (axios.isAxiosError(e)) {
            const errors = e.response?.data?.non_field_errors;

            throw errors ?? 'Something went wrong';
          } else {
            throw 'Something went wrong';
          }
        }

        set({ loading: false, payments: fillDraftFrom(0) });
      },
    }),
    {
      name: 'payouts',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({ payments: state.payments }),
    },
  ),
);

export const useSubmitPayments = () => {
  const paymentStore = usePaymentStore();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return async () => {
    try {
      await paymentStore.submitPayments();
      queryClient.resetQueries(['getPayments']);
      queryClient.resetQueries(['user']);
      paymentStore.reset();

      enqueueSnackbar('Платежи успешно созданы', { variant: 'success' });
      navigate('/');
    } catch (e: unknown) {
      enqueueSnackbar(e as string, { variant: 'error' });
      navigate('/create');
    }
  };
};

export type PaymentMethod = {
  slug: string;
  name: string;
  commission: number;
  has_secondary_account: boolean;
};

export type UsePaymentMethodsParams = {
  account?: string;
  query?: string;
};

export type PaymentRowData = {
  id: number;
  cardNumber: string;
  paymentMethod?: PaymentMethod;
  amount: number;
  comment: string;
  secondaryAccount: string;
  error?: Record<string, any>;
  backendId: number | null;
  suggestions: PaymentMethod[];
};

export type CreatePaymentParams = {
  slug: string; // bank slug
  account: string; // cardNumber
  value: number; // amount
  comment?: string;
  secondary_account?: string;
};

export type CreateRegistryParams = {
  ids: number[];
};

export type CreatePaymentResponse = {
  id: number;
};

export type Rate = {
  name: string;
  price: number;
};

export type Payment = {
  id: number;
  account: string;
  method: string;
  value: string;
  comment: string | null;
  commission: number | null;
  full_value_usdt: number | null;
  status: 'new' | 'done' | 'error';
  created_at: string;
  rate: Rate | null;
};

export type PaymentsResponse = {
  results: Payment[];
  total_value: number;
  total_value_usdt: number;
  count: number;
};

export type PayoutTotals = {
  payouts_value_rub: number;
  payouts_with_commission_value_rub: number;
};

export type UsePaymentsParams = {
  from: Date;
  to: Date;
  page?: number;
};

export const usePayments = ({ from, to }: UsePaymentsParams) => {
  const dateAfter = format(startOfDay(from), 'yyyy-MM-dd');
  const dateBefore = format(endOfDay(to), 'yyyy-MM-dd');
  const { data, isLoading } = useQuery<PaymentsResponse>(['getPayments', dateAfter, dateBefore], () =>
    axios.get(`/api/v1/payouts/?date_after=${dateAfter}&date_before=${dateBefore}`).then((res) => res.data),
  );

  return {
    isLoading,
    total: data?.total_value,
    totalUSDT: data?.total_value_usdt,
    payments: data?.results,
  };
};
