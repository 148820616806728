import { Button, CircularProgress, Typography } from '@mui/material';
import { Popup } from 'components/Popup';
import { usePaymentStore, usePaymentTotal, useSubmitPayments } from 'hooks/payment';
import { useNavigate } from 'react-router-dom';

export const ConfirmPayment = () => {
  const navigate = useNavigate();
  const paymentTotal = usePaymentTotal();
  const submitPayments = useSubmitPayments();
  const paymentStore = usePaymentStore();

  const onCreatePayment = async () => {
    await submitPayments();
  };

  return (
    <Popup className="flex align-middle justify-center h-screen" onClose={() => navigate('/')}>
      <div className="max-w-md w-full flex flex-col justify-center">
        <Typography className="mb-4" variant="h3">
          Расчёт
        </Typography>
        <Typography>Итого сумма выплат {paymentTotal.total} ₽</Typography>
        <Typography>
          К оплате с комиссией <b>{paymentTotal.totalWithCommission} ₽</b>
        </Typography>
        <Button className="mt-8" disabled={paymentStore.loading} variant="contained" onClick={onCreatePayment}>
          {paymentStore.loading ? <CircularProgress size={24} /> : 'Далее'}
        </Button>
        <Button className="mt-3" variant="outlined" onClick={() => navigate('/create')}>
          Назад к редактированию
        </Button>
      </div>
    </Popup>
  );
};
