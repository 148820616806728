import { Button, CircularProgress } from '@mui/material';
import { PaymentRow } from 'components/PaymentRow';
import { Popup } from 'components/Popup';
import { Rates } from 'components/Rates';
import { PaymentRowData, usePaymentStore } from 'hooks/payment';
import { useNavigate } from 'react-router-dom';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useEffect, useRef } from 'react';
import { parseAmount } from 'utils/amount';

const FormRow = ({ index, style, data }: any) => {
  const paymentStore = usePaymentStore();
  const convertTable = async (text: string, start = 0) => {
    const rows = text.split('\n');

    const oldPayments = paymentStore.payments.slice(0, start);

    const payments: PaymentRowData[] = rows
      .filter((row) => !!row)
      .map((row, index) => {
        const [account, value, comment] = row.split('\t');

        return {
          id: index + start,
          cardNumber: account,
          amount: parseAmount(value),
          bank: '',
          secondaryAccount: '',
          comment: comment || '',
          backendId: null,
          suggestions: [],
        };
      });

    paymentStore.setPayments([...oldPayments, ...payments]);
  };

  const updateAmounts = async (text: string, start = 0) => {
    paymentStore.updateAmounts(text, start);
  };

  const onRemove = (id: number) => {
    paymentStore.remove(id);
  };

  const field = data[index];

  return (
    <div key={field.id} style={style}>
      <PaymentRow
        error={field.error}
        number={index + 1}
        paymentMethodById={(id: string) => paymentStore.paymentMethods[id]}
        value={field}
        onChange={(value) => {
          paymentStore.updatePayment(index, value);
        }}
        onPaste={(text) => {
          convertTable(text, index);
        }}
        onPasteAmount={(text) => updateAmounts(text, index)}
        onRemove={onRemove}
      />
    </div>
  );
};

export const CreatePayment = () => {
  const navigate = useNavigate();

  const paymentsStore = usePaymentStore();
  const listRef = useRef<List>(null);

  useEffect(() => {
    listRef.current?.resetAfterIndex(0);
  }, [paymentsStore.payments, paymentsStore.errorIndexes]);

  useEffect(() => {
    paymentsStore.fetchPaymentMethods();
    listRef.current?.resetAfterIndex(0);
  }, []);

  const onSubmit = async (event: any) => {
    event.preventDefault();

    try {
      await paymentsStore.createPayments();
    } catch (e: unknown) {
      // eslint-disable-next-line no-console
      console.error(e);
      listRef.current?.resetAfterIndex(0);

      return;
    }

    navigate('/confirm');
  };

  return (
    <Popup className="max-w-full" onClose={() => navigate('/')}>
      {paymentsStore.loading && (
        <div className="fixed flex flex-col items-center justify-center inset-0 w-screen h-screen z-10 bg-[rgba(100,100,100,0.4)]">
          <div className="backdrop-blur-lg rounded-md p-4 text-center">
            <CircularProgress />
            <div className="mt-4 text-white font-bold">{paymentsStore.progress?.toFixed(0)}%</div>
          </div>
        </div>
      )}
      <AutoSizer disableWidth>
        {({ height, width }: any) => (
          <>
            <div className="sticky top-0 pb-0.5">
              <div className="max-w-layout mx-auto">
                <div className="flex justify-between">
                  <h1 className="text-3xl font-bold">Новый реестр</h1>
                  <Rates />
                </div>
                <div className="flex flex-row items-end">
                  <div className="flex-grow mr-4">
                    <div className="text-sm my-3">Суммы выплат от 5 000 руб. до ∞</div>
                  </div>
                  <Button
                    className="mb-3 w-60 flex-grow-0"
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      paymentsStore.reset();
                    }}
                  >
                    Очистить всё
                  </Button>
                </div>
                {paymentsStore.errorIndexes.length > 0 && (
                  <div className="flex text-red-600 text-sm">
                    <div>
                      Проверьте реквизиты выплаты на {paymentsStore.errorIndexes.length === 1 ? 'строке' : 'строках'}
                      :&nbsp;
                    </div>
                    <div>{paymentsStore.errorIndexes.map((index) => index + 1).join(', ')}</div>
                  </div>
                )}
              </div>
            </div>
            <form
              className="-mx-10 mt-2"
              onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
              onSubmit={onSubmit}
            >
              <List
                ref={listRef}
                height={height - 270}
                itemCount={paymentsStore.payments.length}
                itemData={paymentsStore.payments}
                itemKey={(index) => {
                  const payment = paymentsStore.payments[index];

                  return `${payment.id}-${!!payment.error}-${!!payment.paymentMethod?.has_secondary_account}`;
                }}
                itemSize={(index) => {
                  const payment = paymentsStore.payments[index];

                  if (payment.error) {
                    return payment.paymentMethod?.has_secondary_account ? 140 : 65;
                  }

                  return payment.paymentMethod?.has_secondary_account ? 125 : 50;
                }}
                overscanCount={50}
                width={width}
              >
                {FormRow}
              </List>
              <div className="bg-white backdrop-blur-md pt-4 sticky bottom-0">
                <div className="max-w-layout mx-auto">
                  <Button className="mb-3" type="submit">
                    Посчитать и оплатить
                  </Button>
                  <Button
                    className="mb-3"
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      for (let i = 0; i < 10; i++) {
                        paymentsStore.append();
                      }
                    }}
                  >
                    Добавить ещё 10 строк
                  </Button>
                </div>
              </div>
            </form>
          </>
        )}
      </AutoSizer>
    </Popup>
  );
};
