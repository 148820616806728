import { Typography } from '@mui/material';
import { FC } from 'react';

export type StatisticsProps = {
  date: string;
  totalPayments: number;
  count: number;
  totalUSDTDeposit: number;
  totalRubDeposit: number;
};

export const Statistics: FC<StatisticsProps> = ({ date, totalPayments, count, totalUSDTDeposit, totalRubDeposit }) => {
  return (
    <div className="flex flex-col my-6 p-10 rounded-2xl bg-secondary">
      <Typography className="text-2xl" variant="h3">
        Статистика за {date}
      </Typography>
      <div className="flex justify-between">
        <div className="w-[180px]">
          <img alt="sum" className="w-10 h-10 mb-5 mt-6" src="/static/usdt.svg" />
          <div className="text-xl font-semibold">{`${totalUSDTDeposit
            .toLocaleString()
            .replaceAll(/[\,\.]/g, ' ')} USDT`}</div>
          <div className="text-sm">Сумма пополнений</div>
        </div>
        <div className="w-[180px]">
          <img alt="sum" className="w-10 h-10 mb-5 mt-6" src="/static/deposit-sum.svg" />
          <div className="text-xl font-semibold">{totalRubDeposit.toLocaleString().replaceAll(/[\,\.]/g, ' ')} ₽</div>
          <div className="text-sm">Сумма пополнений наличными</div>
        </div>
        <div className="w-[180px]">
          <img alt="sum" className="w-10 h-10 mb-5 mt-6" src="/static/rub.svg" />
          <div className="text-xl font-semibold">{totalPayments.toLocaleString().replaceAll(/[\,\.]/g, ' ')} ₽</div>
          <div className="text-sm">Сумма выплат</div>
        </div>
        <div className="w-[180px]">
          <img alt="count" className="w-10 h-10 mb-5 mt-6" src="/static/count.svg" />
          <div className="text-xl font-semibold">{count.toLocaleString().replaceAll(/[\,\.]/g, ' ')}</div>
          <div className="text-sm">Количество выплат</div>
        </div>
      </div>
    </div>
  );
};
