import { HomeCard } from 'components/HomeCard';
import { useLogout, useUser } from 'hooks/user';
import { Button, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Header } from 'components/Header';
import { useCreateBill } from 'hooks/bill';
import { Payments } from './Payments';
import { History } from './History';
import { FC, useState } from 'react';
import { format } from 'date-fns';

const currencyToSign = {
  rub: '₽',
  usdt: '$',
} as const;

export const Home: FC<{ showHistory: boolean }> = ({ showHistory }) => {
  const { user } = useUser();
  const { logout } = useLogout();

  const navigate = useNavigate();
  const balance = user?.company.balance || 0;
  const balanceImage = `/static/balance-${balance > 0 ? 'positive' : balance < 0 ? 'negative' : 'zero'}.png`;
  const { isCreated, createBill } = useCreateBill();
  const [selectedRange, setSelectedRange] = useState<NonEmptyDateRange<Date>>([new Date(), new Date()]);
  const [from, to] = selectedRange;

  return (
    <div className="w-full min-h-screen bg-white">
      <div className="flex flex-col max-w-layout mx-auto w-full bg-transparent">
        <Header title={user?.company.name ?? ''} onLogout={logout} />
        <div className="w-full">
          <div className="flex">
            <HomeCard
              className="w-304"
              image="/static/create.png"
              subtitle="Одному или нескольким людям"
              title="Сделать выплату"
            >
              <Link className="text-white text-xs underline decoration-white" component={RouterLink} to="/create">
                Сделать выплату &rarr;
              </Link>
            </HomeCard>
            <HomeCard
              dark
              className="w-196"
              image={balanceImage}
              subtitle="Баланс"
              title={`${user?.company.balance} ${currencyToSign[user?.company.deposit_currency ?? 'rub']}`}
            >
              {isCreated ? (
                <div className="text-black text-xs">
                  Счёт выставлен <br /> в рабочий чат &rarr;
                </div>
              ) : (
                <Link
                  className="text-black text-xs underline decoration-black cursor-pointer"
                  onClick={() => createBill()}
                >
                  Рассчитаться &rarr;
                </Link>
              )}
            </HomeCard>
            <div className="flex flex-col justify-between">
              <Button
                className="flex items-center justify-between w-[300px] p-6 text-lg border-0"
                color="secondary"
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate('/deposit')}
              >
                Внести депозит
              </Button>
              {!showHistory ? (
                <Button
                  className="flex items-center justify-between w-[300px] p-6 text-lg border-0"
                  color="secondary"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => navigate('/history')}
                >
                  История попополнений
                </Button>
              ) : (
                <form action="/api/v1/overall-report/" method="POST" target="_blank">
                  <input name="date_after" type="hidden" value={format(from, 'yyyy-MM-dd')} />
                  <input name="date_before" type="hidden" value={format(to, 'yyyy-MM-dd')} />
                  <Button
                    className="flex items-center justify-between w-[300px] px-6 py-5 text-left leading-5 text-md border-0"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    type="submit"
                  >
                    Скачать отчёт
                    <br />
                    Пополнений и выплат
                  </Button>
                </form>
              )}
            </div>
          </div>
          {showHistory ? (
            <History selectedRange={selectedRange} setSelectedRange={setSelectedRange} />
          ) : (
            <Payments selectedRange={selectedRange} setSelectedRange={setSelectedRange} />
          )}
        </div>
      </div>
    </div>
  );
};
