import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { LoginVariables, useLogin, useUser } from 'hooks/user';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export const Login = () => {
  const { handleSubmit, control } = useForm<LoginVariables>();
  const { login, error, isLoggingIn } = useLogin();
  const { isAuthorized, isLoading } = useUser();
  const navigate = useNavigate();
  const { state } = useLocation();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isAuthorized) {
    return <Navigate to="/" />;
  }

  const onSubmit = async ({ email, password }: LoginVariables) => {
    if (isLoggingIn) {
      return;
    }

    await login({
      email,
      password,
    });

    navigate((state as any)?.from ?? '/');
  };

  return (
    <div className="flex justify-center items-center h-screen bg-bg-primary">
      <div className="flex flex-col bg-white rounded-2xl shadow-md w-login p-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography className="mb-6" variant="h3">
            Вход
          </Typography>

          {error?.non_field_errors?.map((message, key) => (
            <Typography key={key} className="text-red-600 mb-3" variant="body1">
              {message}
            </Typography>
          ))}

          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TextField
                error={!!error?.email}
                helperText={error?.email?.[0]}
                placeholder="Введите почту"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <TextField
                className="mt-3"
                placeholder="Введите пароль"
                type="password"
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Button className="mt-3" type="submit" variant="contained">
            {isLoggingIn ? <CircularProgress size={24} /> : 'Войти в аккаунт'}
          </Button>
        </form>
      </div>
    </div>
  );
};
