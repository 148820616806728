import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { Popup } from 'components/Popup';
import { useAddDeposit } from 'hooks/deposit';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AddDeposit = () => {
  const navigate = useNavigate();
  const { addDeposit, isAdded, isAdding } = useAddDeposit();
  const [deposit, setDeposit] = useState('');

  const onAddDeposit = async () => {
    addDeposit({ value: deposit });
  };

  if (isAdded) {
    return (
      <Popup className="flex align-middle justify-center h-screen" onClose={() => navigate('/')}>
        <div className="max-w-md w-full flex flex-col justify-center">
          <Typography className="mb-4" variant="h3">
            Счёт выставлен в рабочий чат
          </Typography>
          <Typography variant="body2">После оплаты, депозит пополнится автоматически</Typography>
          <Button className="mt-8" variant="contained" onClick={() => navigate('/')}>
            Закрыть окно
          </Button>
        </div>
      </Popup>
    );
  }

  return (
    <Popup className="flex align-middle justify-center h-screen" onClose={() => navigate('/')}>
      <div className="max-w-md w-full flex flex-col justify-center">
        <Typography className="mb-4" variant="h3">
          Внести депозит
        </Typography>
        <Typography variant="body2">
          Введите сумму в USDT, на которую хотите пополнить баланс. Счёт будет выставлен в рабочий чат.
        </Typography>
        <TextField
          className="mt-8"
          placeholder="Сумма в USDT"
          type="number"
          onChange={(event) => setDeposit(event.target.value)}
        />
        <Button className="mt-8" variant="contained" onClick={onAddDeposit}>
          {isAdding ? <CircularProgress size={24} /> : 'Выставить счёт'}
        </Button>
      </div>
    </Popup>
  );
};
