import axios from 'axios';
import { useSnackbar } from 'notistack';
import { createElement } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Icon, IconButton } from '@mui/material';

export const useCreateBill = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data, mutateAsync, isLoading } = useMutation(() => axios.post('/api/v1/auto-bill/').then((res) => res.data), {
    onMutate: () => {
      if (data?.message) {
        enqueueSnackbar(data.message, {
          action: () =>
            createElement(
              IconButton,
              { onClick: () => closeSnackbar() },
              createElement(Icon, { className: 'text-white' }, 'close'),
            ),
        });
      }
    },
  });

  return {
    isCreated: !!data?.id,
    createBill: mutateAsync,
    isCreating: isLoading,
  };
};
