import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { endOfDay, format, startOfDay } from 'date-fns';
import { UsePaymentsParams } from './payment';

export type StatisticsResponse = {
  deposit_rub: number;
  deposit_usdt: number;
  total_payouts_rub: number;
  total_payouts_count: number;
};

export const useStatistics = ({ from, to }: UsePaymentsParams) => {
  const dateAfter = format(startOfDay(from), 'yyyy-MM-dd');
  const dateBefore = format(endOfDay(to), 'yyyy-MM-dd');
  const { data, isLoading } = useQuery<StatisticsResponse>(['getStatistics', dateAfter, dateBefore], () =>
    axios.get(`/api/v1/statistics/?date_after=${dateAfter}&date_before=${dateBefore}`).then((res) => res.data),
  );

  return {
    isLoading,
    statistics: data,
  };
};
