import { Icon } from '@mui/material';
import clsx from 'clsx';

type PopupProps = {
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
};

export const Popup = ({ onClose, children, className }: PopupProps) => {
  return (
    <div className="w-screen h-screen">
      <div className="fixed w-6 h-6 right-4 top-4 cursor-pointer z-10" onClick={onClose}>
        <Icon className="text-gray-500">close</Icon>
      </div>
      <div className={clsx('h-screen w-full max-w-layout mx-auto', className)}>{children}</div>
    </div>
  );
};
