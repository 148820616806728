import React from 'react';
import clsx from 'clsx';

type Props = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  image: string;
  className?: string;
  dark?: boolean;
};

export const HomeCard = ({ title, subtitle, children, image, className, dark }: Props) => {
  return (
    <div
      className={clsx('flex flex-col justify-between box-border px-4 py-5 bg-no-repeat bg-contain mr-5', className)}
      style={{ backgroundImage: `url(${image})`, height: '162px' }}
    >
      <div>
        <div className={clsx('text-xl font-semibold', { 'text-slate-900': dark, 'text-white': !dark })}>{title}</div>
        <div className={clsx('text-sm font-normal mt-2', { 'text-slate-900': dark, 'text-white': !dark })}>
          {subtitle}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};
