import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export type User = {
  id: number;
  email: string;
  created_at: string;
  company: {
    balance: string;
    deposit_currency: 'rub' | 'usdt';
    name: string;
    id: number;
  };
};

type UseUserResult = {
  user: User | undefined;
  isLoading: boolean;
  isAuthorized: boolean;
};

export const useUser = (): UseUserResult => {
  const { data, isLoading } = useQuery<User>(['user'], () => axios.get('/api/v1/me/').then((res) => res.data), {});

  return {
    user: data,
    isLoading,
    isAuthorized: !!data,
  };
};

export type LoginVariables = {
  email: string;
  password: string;
};

export type LoginErrorResponse = {
  email: string[];
  non_field_errors: string[];
};

export const useLogin = () => {
  const mutation = useMutation((vars: LoginVariables) => axios.post('/api/v1/login/', JSON.stringify(vars)));

  return {
    login: mutation.mutateAsync,
    isLoggingIn: mutation.isLoading,
    error: (mutation.error as any)?.response?.data as LoginErrorResponse,
  };
};

export const useLogout = () => {
  const client = useQueryClient();
  const mutation = useMutation(() => axios.post('/api/v1/logout/'), {
    onSuccess: () => client.resetQueries(['user'], { exact: true }),
  });

  return {
    logout: mutation.mutateAsync,
    isLoggingOut: mutation.isLoading,
    error: mutation.error,
  };
};
