import { useUser } from 'hooks/user';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export const Authorized = () => {
  const { isAuthorized, isLoading } = useUser();

  const location = useLocation();

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAuthorized) {
    return <Navigate replace state={{ from: location }} to="/login" />;
  }

  return <Outlet />;
};
