import { useNavigate } from 'react-router-dom';
import { Rates } from './Rates';

type HeaderProps = {
  title: string;
  onLogout: () => void;
};

export const Header = ({ onLogout, title }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-between items-center mt-5 mb-10">
      <div className="flex items-center cursor-pointer" onClick={() => navigate('/')}>
        <div className="text-xl font-bold mr-2">Зелёная свеча</div>
        <div>×</div>
        <div className="text-xl font-bold ml-2">{title}</div>
      </div>
      <Rates />
      <div className="cursor-pointer" onClick={onLogout}>
        Выход
      </div>
    </div>
  );
};
