export const parseAmount = (value: string) => {
  if (!value) {
    return 0;
  }

  if (value.match(/\,\d{2}$/)) {
    value = value.replaceAll(/\,\d{2}$/g, '');
  }

  return parseInt(value.replaceAll(/[\,\s]*/g, ''), 10) ?? 0;
};
