import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';

type Rate = {
  name: string;
  price: number;
  created_at: string;
};

export const useRates = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isFetching, refetch } = useQuery<Rate[]>(
    ['rate'],
    () => axios.get('/api/v1/rates/').then((res) => res.data),
    { refetchInterval: 60000, refetchOnMount: true },
  );

  return {
    rates: data,
    isFetching,
    refetch: async () => {
      await refetch();
      enqueueSnackbar('Курс обновлён');
    },
  };
};
