import { Autocomplete, Icon, IconButton, Popper, TextField } from '@mui/material';
import clsx from 'clsx';
import { PaymentMethod, PaymentRowData, defaultPaymentRowData } from 'hooks/payment';
import { useEffect, useMemo, useState } from 'react';

export const REQUIRED_FIELDS = ['cardNumber', 'paymentMethod', 'amount'] as const;
export const FIELD_MAPPING = {
  cardNumber: 'account',
  paymentMethod: 'bank',
  amount: 'value',
};

export type PaymentRowProps = {
  number: number;
  value?: PaymentRowData;
  onChange: (value: PaymentRowData) => void;
  onRemove: (id: number) => void;
  error?: {
    cardNumber?: string;
    bank?: string;
    amount?: string;
    value?: string;
    account?: string;
    secondaryAccount?: string;
  };
  onPaste: (value: string) => void;
  onPasteAmount: (value: string) => void;
  paymentMethodById: (id: string) => PaymentMethod | undefined;
};

export const PaymentRow = ({
  number,
  value = defaultPaymentRowData,
  onChange,
  onRemove,
  error,
  onPaste,
  onPasteAmount,
  paymentMethodById,
}: PaymentRowProps) => {
  const [typedBankValue, setTypedBankValue] = useState('');
  const [focused, setFocused] = useState(false);

  const autocompleteValue = { id: value.paymentMethod?.slug ?? '', label: value.paymentMethod?.name ?? '' };
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  useEffect(() => {
    if (value.paymentMethod?.name) {
      setTypedBankValue(value.paymentMethod.name);
    }
  }, [value.paymentMethod]);

  const errorMessage = useMemo(() => {
    let message = '';

    if (value.error?.non_field_errors?.length) {
      message = message.concat(value.error.non_field_errors?.join(' '), ' ');
    }

    if (typeof value.error === 'string') {
      message = message.concat(value.error);
    }

    if (error?.cardNumber) {
      message = message.concat(error.cardNumber, ' ');
    }

    if (error?.bank) {
      message = message.concat(error.bank, ' ');
    }

    if (error?.amount) {
      message = message.concat(error.amount, ' ');
    }

    if (error?.value) {
      message = message.concat(error.value, ' ');
    }

    if (error?.account) {
      message = message.concat(error.account, ' ');
    }

    return message.trim();
  }, [value.error]);

  return (
    <div className="mb-4 max-w-layoutField mx-auto">
      <div className="flex flex-row items-start group">
        <div className={clsx('pr-4 text-xs w-16 mt-3 text-right', { 'text-red-600': !!errorMessage })}>{number}</div>
        <TextField
          className="mr-2 w-64 basis-auto grow-1 shrink-0"
          error={!!value.error?.account || !!error?.cardNumber}
          focused={focused}
          placeholder="Номер карты или телефона"
          value={value.cardNumber}
          onBlur={onBlur}
          onChange={(e) => onChange({ ...value, cardNumber: e.target.value })}
          onFocus={onFocus}
          onPaste={(event) => {
            const text = event.clipboardData.getData('text');

            if (text.includes('\n') || text.includes('\t')) {
              event.preventDefault();
              onPaste(text);
            }
          }}
        />
        <Autocomplete
          disableClearable
          PopperComponent={({ children, anchorEl, open }: any) => {
            return (
              <Popper anchorEl={anchorEl} open={open}>
                {children}
              </Popper>
            );
          }}
          className="relative mr-2 w-28 basis-auto grow-1 shrink-0"
          classes={{ popper: 'w-full' }}
          filterOptions={(options: any) =>
            options.filter((option: any) => option.label.toLowerCase().includes(typedBankValue.toLowerCase()))
          }
          filterSelectedOptions={false}
          freeSolo={true}
          inputValue={typedBankValue}
          options={(value.suggestions ?? []).map((method) => ({ id: method.slug, label: method.name }))}
          renderInput={(params: any) => {
            return (
              <TextField
                error={!!value.error?.slug || !!error?.bank}
                focused={focused}
                placeholder="Банк"
                {...params}
                inputProps={{ ...params.inputProps, sx: { lineHeight: 'initial' } }}
              />
            );
          }}
          size="small"
          value={autocompleteValue}
          onBlur={onBlur}
          onChange={(e, newValue: any) => {
            if (newValue instanceof Object) {
              onChange({ ...value, paymentMethod: paymentMethodById(newValue.id) });
              setTypedBankValue(newValue.label);
            }
          }}
          onFocus={onFocus}
          onInputChange={(e, inputValue) => {
            if (!e) {
              return;
            }

            setTypedBankValue(inputValue);
          }}
        />
        <TextField
          className="mr-2 w-28 basis-auto grow-1 shrink-0"
          error={!!value.error?.value || !!error?.amount}
          focused={focused}
          placeholder="Сумма"
          value={value.amount || ''}
          onBlur={onBlur}
          onChange={(e) => onChange({ ...value, amount: parseFloat(e.target.value) })}
          onFocus={onFocus}
          onPaste={(event) => {
            const text = event.clipboardData.getData('text');

            if (text.includes('\n') || text.includes('\t')) {
              event.preventDefault();
              onPasteAmount(text);
            }
          }}
        />
        <TextField
          focused={focused}
          placeholder="Комментарий"
          value={value.comment}
          onBlur={onBlur}
          onChange={(e) => onChange({ ...value, comment: e.target.value })}
          onFocus={onFocus}
        />
        <IconButton
          className="ml-2 mt-1 h-9 items-start invisible group-hover:visible group-focus-within:visible"
          size="small"
          onClick={() => onRemove(value.id)}
        >
          <Icon>close</Icon>
        </IconButton>
      </div>
      {value.paymentMethod?.has_secondary_account && (
        <div className="mx-10 mt-2">
          <TextField
            className="w-full basis-auto grow-1 shrink-0"
            error={!!value.error?.secondary_account || !!error?.secondaryAccount}
            focused={focused}
            placeholder="Номер телефона"
            value={value.secondaryAccount}
            onBlur={onBlur}
            onChange={(e) => onChange({ ...value, secondaryAccount: e.target.value })}
            onFocus={onFocus}
          />
          <div className="text-xs mt-2">
            В нашей базе отсутствует № телефона к данной карте. В скором времени перевод без номера телефона будет
            невозможен.
          </div>
        </div>
      )}
      {errorMessage && <div className="text-xs mt-0.5 ml-10 text-red-600">{errorMessage}</div>}
    </div>
  );
};
