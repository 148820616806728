import { usePayments } from 'hooks/payment';
import { FC } from 'react';
import { CustomDatePicker } from 'components/DatePicker';
import { ReactComponent as Wait } from 'assets/wait.svg';
import { ReactComponent as Success } from 'assets/success.svg';
import { ReactComponent as Canceled } from 'assets/error.svg';
import clsx from 'clsx';
import { formatRange } from 'utils/date';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export type PaymentsProps = {
  selectedRange: [Date, Date];
  setSelectedRange: (range: [Date, Date]) => void;
};

export const Payments: FC<PaymentsProps> = ({ selectedRange, setSelectedRange }) => {
  const { payments, total, totalUSDT, isLoading } = usePayments({ from: selectedRange[0], to: selectedRange[1] });
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex font-semibold text-2xl mt-16 mb-3">
        <div>Выплаты за</div>
        <CustomDatePicker
          value={selectedRange}
          onChange={(range) => setSelectedRange(range ?? [new Date(), new Date()])}
        />
      </div>
      <div>
        {!!payments?.length && !isLoading && (
          <div className="flex px-4 bg-gray-100 text-xs leading-8 text-gray-400 rounded-lg items-center justify-between mb-2">
            <div className="w-12">№</div>
            <div className="w-16">Дата</div>
            <div className="w-36">Карта или телефон</div>
            <div className="w-32">Банк</div>
            <div className="w-20">Выплата</div>
            <div className="w-20">USDT</div>
            <div className="w-20">Курс</div>
            <div className="flex-1">Комментарий</div>
          </div>
        )}
        {payments?.map((payment) => (
          <div
            key={payment.id}
            className={clsx('flex px-4 text-xs leading-8 items-center justify-between', {
              'text-green-600': payment.status === 'done',
              'text-red-600': payment.status === 'error',
            })}
          >
            <div className="w-12">{payment.id}</div>
            <div className="w-16">{format(new Date(payment.created_at), 'd.MM', { locale: ru })}</div>
            <div className="w-36">{payment.account}</div>
            <div className="w-32">{payment.method}</div>
            <div className="w-20">{payment.value}</div>
            <div className="w-20">{payment.full_value_usdt ?? '-'}</div>
            <div className="w-20">{payment.rate?.price ?? '-'}</div>
            <div className="flex-1">{payment.comment}</div>
            <div className="w-4">
              {payment.status === 'new' && <Wait />}
              {payment.status === 'done' && <Success />}
              {payment.status === 'error' && <Canceled />}
            </div>
          </div>
        ))}
        {!!payments?.length && total != null && (
          <div>
            <div className="flex pt-7 mt-3 border-0 border-t-2 border-dashed border-gray-300">
              <div>Итоговая сумма выплат за {formatRange(selectedRange)}</div>
              <div className="font-semibold">&nbsp;{total} ₽</div>
            </div>
            <div className="flex pt-4 mb-7 border-0">
              <div>Общая сумма USDT ушедшая за выбранный период:</div>
              <div className="font-semibold">&nbsp;{totalUSDT}</div>
            </div>
          </div>
        )}
      </div>
      {!payments?.length && !isLoading && (
        <>
          <div className="mt-4">За {formatRange(selectedRange)} выплат нет, но это можно исправить</div>
          <Button className="mt-8 w-304" onClick={() => navigate('/create')}>
            Сделать выплату
          </Button>
        </>
      )}
    </div>
  );
};
