import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import axios from 'axios';
import { Authorized } from './pages/Authorized';
import { CreatePayment } from 'pages/CreatePayment';
import { ConfirmPayment } from 'pages/ConfirmPayment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { SnackbarProvider } from 'notistack';
import { AddDeposit } from 'pages/AddDeposit';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = '/';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#2ba746',
    },
    secondary: {
      main: '#F1F5F6',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        input: {
          padding: '12px 16px',
          fontSize: '12px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '12px 16px',
          fontSize: '12px',
          lineHeight: 1.5,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '4px',
          marginRight: '4px',
          marginTop: 0,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          width: '240px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          padding: '10px 12px 9px 9px !important',
        },
        multiline: {
          padding: '10px !important',
        },
        inputMultiline: {
          padding: '4px !important',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
        size: 'large',
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#E0FFDD',
            border: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    h3: {
      fontSize: '28px',
      lineHeight: '32px',
      fontWeight: 700,
    },
  },
});

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              autoHideDuration={5000}
            >
              <BrowserRouter>
                <Routes>
                  <Route element={<Authorized />} path="">
                    <Route index element={<Home showHistory={false} />} />
                    <Route element={<Home showHistory />} path="/history" />
                    <Route element={<AddDeposit />} path="deposit" />
                    <Route element={<CreatePayment />} path="create" />
                    <Route element={<CreatePayment />} path="create/:type" />
                    <Route element={<ConfirmPayment />} path="confirm" />
                  </Route>
                  <Route element={<Login />} path="login" />
                </Routes>
              </BrowserRouter>
            </SnackbarProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
