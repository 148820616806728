import { FC, useState } from 'react';
import { CustomDatePicker } from 'components/DatePicker';
import clsx from 'clsx';
import { formatRange } from 'utils/date';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useDeposits } from 'hooks/deposit';
import { useStatistics } from 'hooks/statistics';
import { Statistics } from 'components/Statistics';
import { useUser } from 'hooks/user';
import { Pagination } from '@mui/material';

export type HistoryProps = {
  selectedRange: [Date, Date];
  setSelectedRange: (range: [Date, Date]) => void;
};

export const History: FC<HistoryProps> = ({ selectedRange, setSelectedRange }) => {
  const [page, setPage] = useState(1);
  const { deposits, count, isLoading } = useDeposits({ from: selectedRange[0], to: selectedRange[1], page });
  const { statistics } = useStatistics({
    from: selectedRange[0],
    to: selectedRange[1],
  });
  const { user } = useUser();

  return (
    <div className="pb-10">
      <div className="flex font-semibold text-2xl mt-16 mb-3">
        <div>История пополнений за</div>
        <CustomDatePicker
          value={selectedRange}
          onChange={(range) => setSelectedRange(range ?? [new Date(), new Date()])}
        />
      </div>
      <div>
        {!!deposits?.length && !isLoading && (
          <div className="flex px-4 bg-gray-100 text-xs leading-8 text-gray-400 rounded-lg items-center justify-between mb-2">
            <div className="w-10">№</div>
            <div className="w-28">Дата и время</div>
            <div className="w-28">Вид пополнения</div>
            <div className="w-32">USDT</div>
            <div className="w-10">Курс</div>
            <div className="w-28">Депозит •‎ {user?.company.deposit_currency === 'rub' ? '₽' : 'USDT'}</div>
          </div>
        )}
        {deposits?.map((deposit) => (
          <div key={deposit.id} className={clsx('flex px-4 text-xs leading-8 items-center justify-between')}>
            <div className="w-10">{deposit.id}</div>
            <div className="w-28">{format(new Date(deposit.created_at), 'd.MM.yyyy •‎ hh:mm', { locale: ru })}</div>
            <div className="w-28">{deposit.method}</div>
            <div className="w-32">{deposit.input_value ?? '-'}</div>
            <div className="w-10">{deposit.rate?.price ?? '-'}</div>
            <div className="w-28">{deposit.output_value ?? '-'}</div>
          </div>
        ))}
        {!!deposits?.length && (
          <Pagination
            className="mt-4 flex justify-center"
            count={Math.ceil(count / 10)}
            page={page}
            shape="rounded"
            variant="outlined"
            onChange={(e, page) => setPage(page)}
          />
        )}
      </div>
      {!deposits?.length && !isLoading && (
        <>
          <div className="mt-4">За {formatRange(selectedRange)} пополнений нет</div>
        </>
      )}
      {!!statistics && (
        <Statistics
          count={statistics.total_payouts_count}
          date={formatRange(selectedRange)}
          totalPayments={statistics.total_payouts_rub}
          totalRubDeposit={statistics.deposit_rub}
          totalUSDTDeposit={statistics.deposit_usdt}
        />
      )}
    </div>
  );
};
