import { Icon, IconButton } from '@mui/material';
import clsx from 'clsx';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useRates } from 'hooks/rate';

export const Rates = () => {
  const { rates, isFetching, refetch } = useRates();

  return (
    <div className="flex items-center">
      {rates?.map(({ name, price, created_at }) => (
        <div key={name} className="flex flex-col items-center">
          <div className="flex items-center">
            <div className="text-sm mr-1">{name}</div>
            <div className="text-base font-bold">{price}</div>
          </div>
          <div className="text-xs text-gray-400">
            Обновлён {format(new Date(created_at), 'dd MMM HH:mm', { locale: ru })}
          </div>
        </div>
      ))}
      <div className="ml-2">
        <IconButton size="small" onClick={() => refetch()}>
          <Icon className={clsx({ 'animate-spin': isFetching })}>refresh</Icon>
        </IconButton>
      </div>
    </div>
  );
};
